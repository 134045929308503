// extracted by mini-css-extract-plugin
export var ColumbiaCareLogo = "location-module--ColumbiaCareLogo--4dadb";
export var EmailFormHeader = "location-module--EmailFormHeader--1baf5";
export var HeroButton = "location-module--HeroButton--a4e74";
export var LocationHero = "location-module--LocationHero--5c63b";
export var LocationHeroHeader = "location-module--LocationHeroHeader--0f60c";
export var LocationHeroInner = "location-module--LocationHeroInner--ba30a";
export var LoyaltyProgram = "location-module--LoyaltyProgram--3d797";
export var StoreAddress = "location-module--StoreAddress--10c3c";
export var StoreBody = "location-module--StoreBody--39d33";
export var StoreDetails = "location-module--StoreDetails--f00a7";
export var StoreDirections = "location-module--StoreDirections--8a74d";
export var StoreEmailSignup = "location-module--StoreEmailSignup--32221";
export var StoreInfo = "location-module--StoreInfo--7c0e2";
export var StoreName = "location-module--StoreName--d1a0c";
export var StoreOffers = "location-module--StoreOffers--e1e57";
export var StorePhone = "location-module--StorePhone--f04fc";
export var campaign_contatiner = "location-module--campaign_contatiner--c6202";
export var location_header_button_container = "location-module--location_header_button_container--e7461";